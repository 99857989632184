import React from 'react';

import SEO from '@components/seo';

import Contact from '../components/contact';
import Hero from '../components/hero';
import Layout from '@src/components/layout';

// Due to dynamic path handling with gatsby redirects
// there is a flash of 404 on pages like referral/*
//
// Checking for window when rendering NotFound was suggested in the issue thread here
// https://github.com/gatsbyjs/gatsby/issues/5329
const isBrowser = typeof window !== 'undefined' && window;

const NotFoundPage = () => {
  return (
    isBrowser && (
      <Layout newStyle>
        <SEO title="Sidan hittades inte" noIndex />
        <Hero
          breadcrumbs={[{ path: '/', name: 'Hem' }]}
          title="Sidan kunde inte hittas"
          subtitleMarkdown="Sidan du försökt besöka finns inte."
        />
        <Contact isLargeContact={true} hideFAQ />
      </Layout>
    )
  );
};

export default NotFoundPage;
